import React from 'react'
import { graphql, StaticQuery } from "gatsby";
import Prose from "../utility/prose";
import axios from 'axios'

class AlertForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: '',
      last_name: '',
      street_address: '',
      city: '',
      zip: '',
      mobile: '',
      mobilecheck: false,
      email: '',
      emailcheck: false,
      formError: [],
      formSuccess: '',
    }
  }

  submit = (event) => {
    event.preventDefault()
    this.setState({ formSuccess: '', formError: [] }, this.handleFormEntry)


  }

  handleFormEntry = () => {
    // console.log('SUBMIT')
    // console.log('name: '+this.state.name)
    // console.log('email: '+this.state.email)
    // console.log('message: '+this.state.message)
    // console.log('agreement: '+this.state.agreement)
    // console.log('response: '+this.state.captcha)

    if (this.state.first_name === '' ||
      this.state.last_name === '' ||
      this.state.street_address === '' ||
      this.state.city === '' ||
      this.state.zip === '' ||
      this.state.mobile === '' ||
      this.state.mobilecheck === false ||
      this.state.emailcheck === false ||
      this.state.email === ''
    ) {
      this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
    } else {
      let formData = new FormData()
      formData.set('first_name', this.state.first_name)
      formData.set('last_name', this.state.last_name)
      formData.set('street_address', this.state.street_address)
      formData.set('city', this.state.city)
      formData.set('zip', this.state.zip)
      formData.set('mobile', this.state.mobile)
      formData.set('email', this.state.email)
      formData.set('state', 'TX')
      formData.set('client_id', 58)

      axios.post('https://inten.touchstonedistrictservices.com/api/account-registration', formData,
        //axios.post('http://touchstone-server.test/mailer', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
          }
        }).then(function (response) {
          console.log('Response');
          if (response.data.success === true) {
            this.clearForm()
            this.setState({ formSuccess: "Your account has been registered." })
          } else {
            this.setState({ formError: this.state.formError.concat(["Something went wrong, check your entry and try again."]) })
          }

        }.bind(this)).catch(function (error) {
          console.log(error.response)
        })

    }

  }

  clearForm = () => {
    this.setState({ first_name: '', last_name: '', street_address: '', city: '', zip: '', mobile: '', email: '' })
  }

  changeFirstName = (event) => {
    this.setState({ first_name: event.target.value })
  }
  changeLastName = (event) => {
    this.setState({ last_name: event.target.value })
  }
  changeStreetAddress = (event) => {
    this.setState({ street_address: event.target.value })
  }
  changeCity = (event) => {
    this.setState({ city: event.target.value })
  }
  changeZip = (event) => {
    this.setState({ zip: event.target.value })
  }
  changeMobile = (event) => {
    this.setState({ mobile: event.target.value })
  }
  changeEmail = (event) => {
    this.setState({ email: event.target.value })
  }
  changeMobileCheck = (event) => {
    this.setState({ mobilecheck: !this.state.mobilecheck })
  }
  changeEmailCheck = (event) => {
    this.setState({ emailcheck: !this.state.emailcheck })
  }


  render() {
    return (
      <div>
        <div className="m-auto md:w-10/12">
        <div className="w-full">
          <div dangerouslySetInnerHTML={{
            __html: ` <noscript class="notification is-danger" style="display: block;">This form requires a javascript enabled browser to function. Please enable javascript or switch to a compatible browser in order to use this form.</noscript> `,
          }} />

          <form name="contact" onSubmit={this.submit}>
            { this.state.formError.length !== 0 ? (<ul className="list-none text-red-400 border-red-400 border-2 rounded-3xl">{this.state.formError.map((error) => (
                <li className="" key={error} >{error}</li>
            )) }</ul>): < ></ > }
            { this.state.formSuccess === '' ? ( < ></ > ) : (<ul className="list-none text-green-400 border-green-400 border-2 rounded-3xl"><li>{this.state.formSuccess}</li></ul>)}

            <div className="float-left w-1/2 mb-2 pr-2">
                <label htmlFor="first_name" className="text-sm uppercase pl-6">First Name</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="text" name="first_name" placeholder="Enter your first name" onChange={this.changeFirstName} value={this.state.first_name} />
            </div>
            <div className="float-left w-1/2 mb-2 pl-2">
              <label htmlFor="last_name" className="text-sm uppercase pl-6">Last Name</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="text" name="last_name" placeholder="Enter your last name" onChange={this.changeLastName} value={this.state.last_name} />
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="text-sm uppercase pl-6">Email</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="email" name="email" placeholder="Enter your email" onChange={this.changeEmail} value={this.state.email} />
            </div>
            <div>
              <label className="text-sm uppercase pl-6">
                <input type="checkbox" className="text-purple-600 focus:ring-transparent mx-2" onChange={this.changeEmailCheck} name="email.check" checked={this.state.emailcheck} /> Send me updates by email
              </label>
            </div>
            <div className="mb-2">
              <label htmlFor="mobile" className="text-sm uppercase pl-6">Mobile</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="text" name="mobile" placeholder="Enter your mobile number" onChange={this.changeMobile} value={this.state.mobile} />
            </div>
            <div>
              <label className="text-sm uppercase pl-6">
                <input type="checkbox" className="text-purple-600 focus:ring-transparent mx-2" onChange={this.changeMobileCheck} name="mobile.check" checked={this.state.mobilecheck} /> Send me updates by text*
              </label>
            </div>
            <div className="mb-2">
              <label htmlFor="street_address" className="text-sm uppercase pl-6">Street Address</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="text" name="street_address" placeholder="Enter your street address" onChange={this.changeStreetAddress} value={this.state.street_address} />
            </div>
            <div className="float-left w-1/2 mb-2 pr-2">
              <label htmlFor="city" className="text-sm uppercase pl-6">City</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="text" name="city" placeholder="Enter your city" onChange={this.changeCity} value={this.state.city} />
            </div>
            <div className="float-left w-1/2 mb-4 pl-2">
              <label htmlFor="zip" className="text-sm uppercase pl-6">Zip Code</label>
                <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6" type="text" name="zip" placeholder="Enter your 5 digit zip code" onChange={this.changeZip} value={this.state.zip} />
            </div>
            <div className="mb-14">
                <input className="inline-block text-base uppercase bg-green-700 hover:bg-green-800 mr-1 mb-2 px-4 py-2 rounded-full leading-none" type="submit" name="submit" value="Submit" />
            </div>
          </form>
        </div>
        </div>
      </div>
    )
  }
}

export default AlertForm

